import styled from "@emotion/styled/macro";
import type { ButtonProps } from "../../../styled";
import { ButtonStyledAsLink } from "../../../styled";
import { MQ, typographyToCss } from "../../../styles/helpers";
import { colorsAsCssVariable } from "../../../styles/theme/colors";
import { xxlTheme } from "../../../styles/xxl-theme";

const { typography, spaces, colors } = xxlTheme;

export const Container = styled.div`
  width: 100%;

  ${MQ("tablet")} {
    display: flex;
    align-items: center;
  }
`;

export const AvailabilityFieldset = styled("fieldset")`
  border: none;
  display: inline-flex;
  align-self: baseline;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  margin: 0;

  ${MQ("tablet")} {
    width: auto;
    padding: 0;
    display: flex;
    border-color: transparent;
  }

  & label {
    margin: 0;
    margin-right: ${spaces.mini};

    &:first-of-type {
      & .MuiCheckbox-root {
        padding-left: 0;
      }
    }

    & .MuiFormControlLabel-label {
      ${typographyToCss(typography.mediumRegular)};
      text-wrap: wrap;
      text-wrap: pretty;

      & svg {
        font-size: ${spaces.regular};
        vertical-align: bottom;
        margin-right: ${spaces.micro};
      }
    }

    & .Mui-checked + .MuiFormControlLabel-label {
      ${typographyToCss(typography.mediumBold)}
    }
  }

  &.new-plp-filters {
    align-items: center;
    margin: 0 ${spaces.miniRegular} 0 0;
    padding: ${spaces.smallLarge} 0;
    border-top: 0;
    width: auto;

    ${MQ("tablet")} {
      width: auto;
      padding: 0;
      border: none;
    }
  }
`;

const EditButtonStyled = styled(ButtonStyledAsLink)`
  position: relative;
  color: ${colorsAsCssVariable.xxlWebBlack};
  ${typographyToCss({
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    fontFamily: "var(--font-family-medium)",
  })}

  ${MQ("tablet")} {
    ${typographyToCss({
      fontSize: 15,
    })}
  }
`;

export const EditButton = (props: ButtonProps & React.PropsWithChildren) => (
  <EditButtonStyled type="button" {...props} />
);

export const StoreNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${spaces.smallRegular};
  background-color: ${colorsAsCssVariable.xxlLightGreen};
`;

type StoreNameProps = {
  isBold: boolean;
};
export const StoreName = styled.p<StoreNameProps>`
  display: flex;
  align-items: center;
  color: var(--color-xxl-web-black);
  font-family: ${({ isBold }) =>
    isBold ? "var(--font-family-bold)" : "var(--font-family-regular)"};
  gap: ${spaces.micro};

  ${typographyToCss({
    fontSize: 12,
    fontFamily: "var(--font-family-medium)",
  })}

  ${MQ("tablet")} {
    ${typographyToCss({
      fontSize: 15,
    })}
    margin: 0;
  }
`;

export const AvailabilityForm = styled.form`
  ${MQ("tablet")} {
    display: flex;
    align-items: center;
  }
  /* adjustments of SelectedStoreInfoMessage for new PLP filters toggle */
  & > p {
    margin-left: 0;
    transform: translateY(-${spaces.smallRegular});
  }
`;

type WarningMessageStyledProps = {
  addTopMargin: boolean;
};

export const WarningMessageStyled = styled.p<WarningMessageStyledProps>`
  align-items: center;
  background-color: ${colors.xxlLightAmber};
  box-sizing: border-box;
  color: ${colors.xxlWebBlack};
  display: flex;
  margin: 0 ${spaces.miniMicro};
  padding: ${spaces.miniMicro};
  width: 100%;
  ${({ addTopMargin }) =>
    addTopMargin ? `margin-top: ${spaces.smallRegular};` : ``};
  ${typographyToCss(typography.tinyRegular)};
  ${MQ("tablet")} {
    width: auto;
    ${typographyToCss(typography.smallRegular)};
  }
`;

export const WarningMessageIcon = styled.span`
  align-self: baseline;
  display: block;
  flex-shrink: 0;
  font-size: ${spaces.regular};
  line-height: 1;
  margin-right: ${spaces.miniMicro};
`;

export const WarningMessageText = styled.span`
  flex-wrap: wrap;
`;

export const LineDivider = styled.span`
  height: 16px;
  border-left: ${spaces.hair} solid ${colors.xxlDarkGrey};
`;

export const EditButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.mini};
`;
