import { FILTER_SEPARATOR } from "@/react-app/constants";
import { AVAILABILITY } from "@/react-components/Search/SearchFetchProductsHelper.types";
import type { PreferredStoreIds } from "@/react-utils/Cookie";
import { hasValue } from "@xxl/common-utils";
import {
  Sort as SortParametersForElevate,
  type SortType,
} from "@xxl/product-search-api";
import type { NextRouter } from "next/router";
import {
  NR_OF_PRODUCTS_PER_PAGE,
  URL_PARAMETERS,
  ELEVATE_MAX_SKIP_LIMIT,
  type UrlParameterName,
} from "../../constants";
import type { Parameters } from "../useProductListParams";
import { EntitySortingParameterCustomNameEnum as SortParametersForLoop } from "@xxl/search-api";

const { relevance, cheapest, expensive, highest_rating, highest_discount } =
  SortParametersForLoop;

const { DISCOUNT, PRICE_ASCENDING, PRICE_DESCENDING, RATING, RELEVANCE } =
  SortParametersForElevate;

const SORT_PARAMETERS_MAP = {
  [cheapest]: PRICE_ASCENDING,
  [expensive]: PRICE_DESCENDING,
  [highest_discount]: DISCOUNT,
  [highest_rating]: RATING,
  [relevance]: RELEVANCE,
  undefined: undefined, // Default to undefined and let Elevate decide sort value
};

const getSearchParams = () => new URLSearchParams(window.location.search);

const pushToRouter = async ({
  params,
  pathname,
  router,
  scrollToTop = false,
  shallow = false,
}: {
  params: URLSearchParams;
  pathname: string | null;
  router: NextRouter;
  scrollToTop?: boolean;
  shallow?: boolean;
}) => {
  const browserPathName = window.location.pathname;
  const path = pathname ?? browserPathName;
  const queryString = hasValue(params.toString())
    ? `?${params.toString()}`
    : "";

  /**
   * When updating with params (facets etc.) we don't want to keep the path in history (push) but rather
   * replace the current state. This means that when the user navigates back, they navigate to a page rather than every previously set filter.
   */
  if (params.size > 0) {
    void router.replace(`${path}${queryString}`, undefined, {
      scroll: scrollToTop,
      shallow,
    });
  }

  return router.push(`${path}${queryString}`, undefined, {
    scroll: scrollToTop,
    shallow,
  });
};

export const removeUrlParametersForFacetsAndPages = (
  router: NextRouter,
  pathname: string | null,
  shallow: boolean
) => {
  const params = getSearchParams();
  Array.from(params.entries())
    .filter(
      ([key]) =>
        key.includes(URL_PARAMETERS.facet.name) ||
        key === URL_PARAMETERS.page.name
    )
    .forEach(([key]) => params.delete(key));
  return pushToRouter({ params, pathname, router, shallow });
};

export const deleteUrlParameter = (
  name: UrlParameterName,
  router: NextRouter,
  pathname: string | null,
  shallow = true
) => {
  const params = getSearchParams();
  params.delete(name);
  return pushToRouter({ params, pathname, router, shallow });
};

export const setUrlParameters = ({
  parameters,
  pathname,
  router,
  scrollToTop,
  shallow,
}: {
  parameters: {
    name: UrlParameterName;
    value: string | null;
  }[];
  pathname: string | null;
  router: NextRouter;
  scrollToTop?: boolean;
  shallow?: boolean;
}): Promise<boolean> => {
  const params = getSearchParams();
  parameters.forEach(({ name, value }) =>
    hasValue(value) ? params.set(name, value) : params.delete(name)
  );
  return pushToRouter({ params, pathname, router, scrollToTop, shallow });
};

export const setUrlParameter = ({
  name,
  value,
  router,
  scrollToTop,
  shallow,
}: {
  name: UrlParameterName;
  value: string | null;
  router: NextRouter;
  scrollToTop?: boolean;
  shallow?: boolean;
}) =>
  setUrlParameters({
    parameters: [{ name, value }],
    pathname: null,
    router,
    scrollToTop,
    shallow,
  });

export const parametersToRequest = (
  parameters: Omit<
    Parameters,
    "availability" | "categoryPath" | "showAll" | "cid"
  > & {
    availability: AVAILABILITY[];
    numberOfProductsPerPage: number;
    selectedStores: PreferredStoreIds;
  }
): {
  limit: number;
  query: string;
  skip: number;
  channels?: string;
  sort?: SortType;
  storeIds?: string;
} => {
  const {
    availability,
    numberOfProductsPerPage,
    page,
    query,
    selectedStores,
    sort: initalSort,
  } = parameters;
  const isValidElevateSortValue = Object.values(SortParametersForElevate).some(
    (p) => p === initalSort
  );
  const sort = isValidElevateSortValue
    ? initalSort
    : SORT_PARAMETERS_MAP[initalSort as keyof typeof SORT_PARAMETERS_MAP];
  const skip = (Number(page) - 1) * numberOfProductsPerPage;
  const channels =
    availability.length === 0
      ? undefined
      : availability.toString().replace(",", FILTER_SEPARATOR);
  const hasInvalidAvailability =
    selectedStores.length === 0 && availability.includes(AVAILABILITY.STORE);
  const storeIds =
    selectedStores.length === 0
      ? undefined
      : selectedStores.toString().replaceAll(",", FILTER_SEPARATOR);
  const finalSkip =
    skip < 0
      ? 0
      : skip > ELEVATE_MAX_SKIP_LIMIT
        ? ELEVATE_MAX_SKIP_LIMIT - NR_OF_PRODUCTS_PER_PAGE
        : skip;
  return {
    channels: hasInvalidAvailability ? undefined : channels,
    limit: numberOfProductsPerPage,
    query,
    skip: finalSkip,
    sort,
    storeIds,
  };
};
