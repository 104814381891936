import type { FetchDataClientSide } from "@/hooks/useProductList/types";
import { useSharedData } from "@/react-app/contexts/SharedData";
import type { RangeValue } from "@/react-components/Common/RangeSlider/RangeSlider.types";
import {
  RANGE_FILTER_MAX,
  RANGE_FILTER_MIN,
  stickyFiltersMarginSettings,
} from "@/react-components/Filter/FilterBar/constants";
import { getDistinctFilterItemHref } from "@/react-components/Filter/FilterHelper";
import { ProductSkeleton } from "@/react-components/Product/ProductSkeleton";
import type { AVAILABILITY } from "@/react-components/Search/SearchFetchProductsHelper.types";
import type { AvailabilityBarProps } from "@/react-components/Sort/AvailabilitySelector/AvailabilityBar";
import { AvailabilityBar } from "@/react-components/Sort/AvailabilitySelector/AvailabilityBar";
import type { MultipleAvailabilitySelectorProps } from "@/react-components/Sort/AvailabilitySelector/MultipleAvailabilitySelector";
import { hasNoValue, hasValue } from "@xxl/common-utils";
import type { LongTailData } from "@xxl/frontend-api";
import type { FacetData, SortOrderData } from "@xxl/product-search-api";
import { useInView } from "framer-motion";
import noop from "lodash/noop";
import { useRouter } from "next/router";
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type PropsWithChildren,
} from "react";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { FilterBar } from "react-app/src/components/Filter/FilterBar/FilterBar";
import type { OnChangeFilterProps } from "react-app/src/components/Filter/FilterMenu/Filters/FilterAccordions/DistinctFilterAccordion/types";
import { ListWrapper } from "react-app/src/components/ProductList/ProductList.styled";
import { useTracking } from "react-app/src/contexts/Tracking";
import { containerMaxWidth } from "react-app/src/styles/xxl-theme";
import { Pagination } from "../Pagination";
import { pageStringToNumber } from "./ProductList.helper";
import { NR_OF_PRODUCTS_PER_PAGE, URL_PARAMETERS } from "./constants";
import { useProductListParams } from "./hooks/useProductListParams";

export type ProductListProps = {
  availability: AVAILABILITY[];
  categoryId: string | null;
  facets: FacetData[] | null;
  isArticleNumbersSearch: boolean;
  isFetchingProducts: boolean;
  longTailFacets: LongTailData[] | null;
  longTailPattern: string | null;
  selectedColumnsNumber: number;
  selectedFilters: {
    [key: string]: (string | number)[];
  };
  sortOrderData: SortOrderData | undefined;
  storesData: MultipleAvailabilitySelectorProps["storesData"];
  totalHits: number;
  onFilterChange?: FetchDataClientSide;
  autoCorrect?: string;
  /**
   * If true the passed in onfetch will automaytically be called on first load and whenevr logged in stae changes.
   * When setting this to false, the parent component must handled that.
   */
  autoFetch?: boolean;
};

const ProductList = ({
  autoCorrect,
  availability,
  categoryId,
  children,
  facets,
  isFetchingProducts,
  longTailFacets,
  longTailPattern,
  onFilterChange,
  selectedColumnsNumber,
  selectedFilters: selectedFiltersFromServer,
  sortOrderData,
  storesData,
  totalHits,
  isArticleNumbersSearch,
}: PropsWithChildren<ProductListProps>) => {
  const fetchDataClientSide = onFilterChange ?? noop;
  const shouldUseShallowRouting = hasValue(onFilterChange);
  const {
    paginate,
    parameters,
    removeAllFacets,
    setFacet,
    setQuery,
    setRangeFacet,
    setStoresAndAvailability,
    sortBy,
  } = useProductListParams(shouldUseShallowRouting);

  const {
    data: {
      configuration: {
        frontendApi: { basePath },
      },
    },
  } = useSharedData();
  const trackers = useTracking();
  const pageCount = Math.ceil(totalHits / NR_OF_PRODUCTS_PER_PAGE);
  const page = pageStringToNumber(parameters.page);
  const { options = [], selected: initialSelectedSort = null } =
    sortOrderData ?? {};
  const scrollTarget = useRef<HTMLDivElement>(null);
  const availabilityBarRef = useRef(null);
  const IsAvailabilityBarInView = useInView(
    availabilityBarRef,
    stickyFiltersMarginSettings
  );
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: (string | number)[];
  }>(selectedFiltersFromServer);
  useEffect(() => {
    setSelectedFilters(selectedFiltersFromServer);
  }, [selectedFiltersFromServer]);
  const [selectedSort, setSelectedSort] = useState<
    SortOrderData["selected"] | null
  >(initialSelectedSort);
  useEffect(() => {
    setSelectedSort(sortOrderData?.selected ?? null);
  }, [sortOrderData]);
  const router = useRouter();

  useEffect(() => {
    router.beforePopState(({ as }) => {
      // Hack to trigger data refetch when navigating with browsers native back/forward because NextJS links in combination with router.replace(..) doesn't
      // https://xxlsports.atlassian.net/browse/XD-15987
      void router.replace(as);
      return true;
    });

    return () => router.beforePopState(() => true);
  }, [router]);

  const scrollToFilters = useCallback(() => {
    const { current } = scrollTarget;

    if (hasNoValue(current)) {
      return;
    }

    const top = current.offsetTop;
    window.requestAnimationFrame(() => window.scrollTo({ top }));
  }, [scrollTarget]);

  const handleChangeRangeFilterValue = useCallback(
    (id: string) =>
      ({ max, min }: RangeValue) => {
        setSelectedFilters((state) => {
          const values = {
            [`${id}.${RANGE_FILTER_MAX}`]: [max],
            [`${id}.${RANGE_FILTER_MIN}`]: [min],
          };
          const newState = {
            ...state,
            ...values,
          };
          fetchDataClientSide({
            availability,
            categoryId,
            page: URL_PARAMETERS.page.default,
            selectedFilters: newState,
            selectedSort,
          });
          void setRangeFacet({ id, max, min });
          scrollToFilters();
          return newState;
        });
      },
    [
      availability,
      categoryId,
      fetchDataClientSide,
      scrollToFilters,
      selectedSort,
      setRangeFacet,
    ]
  );

  const handleChangeFilterValue = useCallback(
    (props: OnChangeFilterProps) => {
      setSelectedFilters((state) => {
        if (
          props.action === "removeAll" ||
          props.action === "removeAllWithLongtailUrl"
        ) {
          const newState = {};
          fetchDataClientSide({
            availability,
            categoryId,
            page: URL_PARAMETERS.page.default,
            selectedFilters: newState,
            selectedSort,
          });
          const { longTailUrl } = props;
          void removeAllFacets({ longTailUrl });
          scrollToFilters();
          return newState;
        }
        const { action, id } = props;
        const selectedFilter = hasValue(state[id]) ? state[id] : [];
        const [value] = props.values;
        const updatedFilters =
          action === "add" || action === "addLongtail"
            ? [...selectedFilter, value]
            : selectedFilter.filter((f) => f !== value);
        const newState = Object.fromEntries(
          Object.entries({
            ...state,
            [id]: updatedFilters,
          }).filter(([, v]) => hasValue(v))
        );
        fetchDataClientSide({
          availability,
          categoryId,
          page: URL_PARAMETERS.page.default,
          selectedFilters: newState,
          selectedSort,
        });
        const longTailUrl =
          hasValue(longTailFacets) &&
          hasValue(longTailPattern) &&
          hasValue(props.values)
            ? getDistinctFilterItemHref(
                basePath,
                Object.entries(state).map(([attributeName, selected]) => ({
                  attributeName,
                  selected,
                  type: "distinct",
                })),
                props.action === "remove",
                props.id,
                props.values[0].toString(),
                longTailFacets,
                longTailPattern
              ).getUpdatedLongTailPath()
            : null;
        if (hasValue(longTailUrl)) {
          void setFacet({
            ...props,
            action: "addLongtail",
            longTailUrl,
            values: updatedFilters,
          });
        } else {
          void setFacet({ ...props, values: updatedFilters });
        }
        scrollToFilters();
        return newState;
      });
    },
    [
      availability,
      categoryId,
      basePath,
      fetchDataClientSide,
      longTailFacets,
      longTailPattern,
      removeAllFacets,
      scrollToFilters,
      selectedSort,
      setFacet,
    ]
  );

  const onChangeSortOption = (value: string) => {
    const castedValue = value as SortOrderData["selected"];
    setSelectedSort(castedValue);
    trackers.sendSortChangeEvent({
      searchQuery: parameters.query,
      value,
    });
    fetchDataClientSide({
      availability,
      categoryId,
      page: URL_PARAMETERS.page.default,
      selectedFilters,
      selectedSort: castedValue,
    });
    void sortBy(value);
    scrollToFilters();
  };

  const onAvailabilityChange: AvailabilityBarProps["onChange"] = (
    stores,
    _availability
  ) => {
    const ids: string[] = stores.reduce(
      (acc: string[], store) => [...acc, store.id],
      []
    );
    setStoresAndAvailability({
      availability: _availability,
      selectedStoreIds: ids,
      shallow: shouldUseShallowRouting,
    });
    fetchDataClientSide({
      availability: _availability,
      categoryId,
      page: URL_PARAMETERS.page.default,
      selectedFilters,
      selectedSort,
      selectedStores: ids,
    });
  };

  const onPaginate = (pageNr: number) => {
    paginate(pageNr);
    fetchDataClientSide({
      availability,
      categoryId,
      page: pageNr,
      selectedFilters,
      selectedSort,
    });
    scrollToFilters();
  };

  useEffect(() => {
    if (hasNoValue(autoCorrect)) {
      return;
    }
    setQuery({ value: autoCorrect, shallow: true });
  }, [autoCorrect, setQuery]);

  return (
    <XxlStack maxWidth={containerMaxWidth} ref={scrollTarget}>
      {!isArticleNumbersSearch && (
        <>
          <FilterBar
            facets={facets}
            longTailFacets={longTailFacets}
            longTailPattern={longTailPattern}
            onChangeFilter={handleChangeFilterValue}
            onChangeRangeFilter={handleChangeRangeFilterValue}
            onChangeSortOption={onChangeSortOption}
            selectedColumnsNumber={selectedColumnsNumber}
            selectedFilters={selectedFilters}
            selectedSort={selectedSort}
            sortOptions={options}
            totalHits={totalHits}
            isStickyFilter={!IsAvailabilityBarInView}
            shouldAutomaticallyScrollToFilterBar={false}
          />
          <AvailabilityBar
            numberOfProducts={totalHits}
            onChange={onAvailabilityChange}
            selectedAvailability={availability}
            selectedColumnsNumber={selectedColumnsNumber}
            storesData={storesData}
            ref={availabilityBarRef}
          />
        </>
      )}
      <ListWrapper
        columnAmount={selectedColumnsNumber}
        isLoading={isFetchingProducts}
        data-testid="product-list"
      >
        {isFetchingProducts
          ? Array(NR_OF_PRODUCTS_PER_PAGE)
              .fill(null)
              .map((_, index) => (
                <li key={`skeleton-${index}`}>
                  <ProductSkeleton
                    selectedColumnsNumber={selectedColumnsNumber}
                  />
                </li>
              ))
          : children}
      </ListWrapper>
      <Pagination onChange={onPaginate} page={page} pageCount={pageCount} />
    </XxlStack>
  );
};

export { ProductList };
