import type { Store as StoreWithId } from "@/react-utils/Stores/stores-helper";
import React, { useState } from "react";
import { XxlStack } from "../../../../components/Common/XxlStack";
import { Icon } from "../../../../components/Icon";
import { StoreStockSelectedDetailsSection } from "../../../../components/StoreStock";
import { useTranslations } from "../../../../contexts/Translations/TranslationsContext";
import { StyledCheckbox } from "../../../../styled";
import { getFontFamily, type TRegionalStore } from "./helpers";
import { ListItem, StoreItem } from "./styles";

type Props = {
  filteredStores: TRegionalStore[];
  stores: StoreWithId[];
  selectedIds: string[];
  isMobile: boolean;
  handleChange: (checked: boolean, storeId: string, storeIds: string[]) => void;
};

export const StoreFieldList = ({
  filteredStores: stores,
  stores: allStores,
  selectedIds,
  isMobile,
  handleChange,
}: Props) => {
  const { t } = useTranslations();
  const [isOpenIds, setIsOpenIds] = useState<string[]>([]);
  const hasIsOpenId = (id: string) => isOpenIds.includes(id);
  const getStore = (id: string) => allStores.find((store) => store.id === id);
  const handleItemClick = (id: string) => {
    const idSet = new Set(isOpenIds);
    const hasId = idSet.has(id);
    if (!hasId) {
      idSet.add(id);
      setIsOpenIds(Array.from(idSet));
      return;
    }
    idSet.delete(id);
    setIsOpenIds(Array.from(idSet));
    return;
  };

  const handleCheckboxClick = (
    id: string,
    checked: boolean,
    regionIds: string[]
  ) => {
    handleChange(checked, id, regionIds);
  };

  if (!isMobile) {
    return (
      <>
        {stores.map(({ regionIds, id, name }) => {
          const isSelected = selectedIds.includes(id);
          const isRegion = regionIds.length > 0;
          const labelText = isRegion
            ? `${name}, ${t("storefinder.warehouses.all")}`
            : name;
          const fontFamily = getFontFamily(isRegion || isSelected);
          return (
            <ListItem key={id}>
              <StyledCheckbox
                label={labelText}
                id={id}
                value={id}
                isRegion={isRegion}
                checked={isSelected}
                style={{ fontFamily }}
                handleChange={(_, checked) => {
                  handleChange(checked, id, regionIds);
                }}
              />
            </ListItem>
          );
        })}
      </>
    );
  }

  return (
    <>
      {stores.map(({ regionIds, id, name }) => {
        const isSelected = selectedIds.includes(id);
        const isRegion = regionIds.length > 0;
        const shouldShowDetails = hasIsOpenId(id);
        const labelText = isRegion
          ? `${name}, ${t("storefinder.warehouses.all")}`
          : name;
        const fontFamily = getFontFamily(isRegion || isSelected);
        return (
          <ListItem key={id}>
            <StoreItem>
              <StyledCheckbox
                label={labelText}
                id={id}
                value={id}
                isRegion={isRegion}
                checked={isSelected}
                style={{ fontFamily }}
                handleChange={(_, checked) => {
                  handleCheckboxClick(id, checked, regionIds);
                }}
              />
              <XxlStack
                width={"100%"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                onClick={() => handleItemClick(id)}
              >
                {!isRegion && !shouldShowDetails && <Icon name="CaretDown" />}
                {!isRegion && shouldShowDetails && <Icon name="CaretUp" />}
              </XxlStack>
            </StoreItem>
            {!isRegion && shouldShowDetails && (
              <StoreStockSelectedDetailsSection
                store={getStore(id)}
                isMobile={true}
              />
            )}
          </ListItem>
        );
      })}
    </>
  );
};
