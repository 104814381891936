import { ErrorBoundary } from "@/react-components/Common/ErrorBoundary/ErrorBoundary";
import { getSmallBanners } from "@/react-components/ProductList";
import { NR_OF_PRODUCTS_BETWEEN_CATEGORY_CONTENT } from "@/react-components/ProductList/constants";
import type { ProductCardDataV2 } from "@/react-utils/ProductData/product-card-data-helper";
import type { ProductListingSmallBannerData } from "@xxl/frontend-api";
import { Fragment, useRef } from "react";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { ProductCardV2 } from "../../ProductListV2/ProductCardV2";

type ProductListItemsProps = {
  productListingSmallBanner: ProductListingSmallBannerData[] | null;
  products: ProductCardDataV2[];
  selectedColumnsNumber: number;
};

export const ProductListItems = ({
  productListingSmallBanner,
  products,
  selectedColumnsNumber,
}: ProductListItemsProps) => {
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");

  const smallBannersIndexRef = useRef(0);
  smallBannersIndexRef.current = 0;

  return (
    <ErrorBoundary>
      {products.map((item, index) => (
        <Fragment key={`${item.code}-${index}`}>
          <li>
            <ProductCardV2
              productData={item}
              isLaptopViewPort={isLaptop}
              isSliderProductList={false}
              nrOfSelectedColumns={selectedColumnsNumber}
              arrayIndex={index}
            />
          </li>
          {getSmallBanners(
            productListingSmallBanner ?? [],
            index,
            smallBannersIndexRef,
            NR_OF_PRODUCTS_BETWEEN_CATEGORY_CONTENT,
            selectedColumnsNumber
          ) ?? null}
        </Fragment>
      ))}
    </ErrorBoundary>
  );
};
