import type { Store } from "@/react-utils/Stores/stores-helper";
import type { TranslationKey } from "../../../translations";
import { AVAILABILITY } from "../../Search/SearchFetchProductsHelper.types";

export const INPUT_ID = {
  ONLINE: "showOnlineAssortment",
  STORE: "showStoreAssortment",
} as const;
export type TInput_ID = (typeof INPUT_ID)[keyof typeof INPUT_ID];

type AvailabilityInput = {
  id: TInput_ID;
  value: AVAILABILITY;
  labelTranslationKey: TranslationKey;
};

export const availabilityInputs: AvailabilityInput[] = [
  {
    id: INPUT_ID.ONLINE,
    value: AVAILABILITY.ONLINE,
    labelTranslationKey: "general.available.online",
  },
  {
    id: INPUT_ID.STORE,
    value: AVAILABILITY.STORE,
    labelTranslationKey: "general.available.in.store",
  },
];

type GetStoreCheckboxLabelParams = {
  t: (key: TranslationKey) => string;
  selectedStores: Store[];
};
export const getStoreCheckboxLabel = ({
  selectedStores,
  t,
}: GetStoreCheckboxLabelParams) => {
  switch (selectedStores.length) {
    case 0:
      return t("general.available.in.store");
    case 1:
      return selectedStores[0].name;
    default:
      return t("general.available.in.store.multiple");
  }
};

export const getMobileSelectedStoreLabel = (
  t: (key: TranslationKey) => string,
  stores: Store[],
  isRegionSelected: boolean,
  regionName = ""
) => {
  if (isRegionSelected) {
    return `${regionName}, ${t("storefinder.warehouses.all")}`;
  }
  switch (stores.length) {
    case 0:
      return t("general.available.in.store");
    case 1:
      return stores[0].name;
    default:
      return t("general.available.in.store.multiple");
  }
};
